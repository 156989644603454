.menu-container {
    padding-top: 5px;
    
    .mc-header {
        display: flex;
        align-items: center;
        background-color: lightgray;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        .title {
            padding: 0 10px;
            flex: 1 1 auto;
            cursor: pointer;
            font-weight: bold;
        }
    }

    &.collapsed {
        .mc-content {
            display: none;
        }
    }
}