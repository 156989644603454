.imsp-lector-section {
    max-width: 750px;

    .page-container.attendance-page {
        .page-content {
            align-items: center;

            .structure-list {
                flex: 1 1 auto;
                max-width: 500px;
                width: 100%;

                .presence {
                    font-size: 20px;
                    padding: 2px 5px;

                    i.fa-question {
                        color: #2f9ad8;
                    }

                    i.fa-check {
                        color: #069106;
                    }

                    i.fa-times {
                        color: #e22020;
                    }

                    .presence-btn {
                        display: inline-block;
                        width: 34px;
                        border-radius: 5px;

                        &:hover {
                            background: #e6e6e6;
                        }
                    }
                }
            }
        }
    }
}
