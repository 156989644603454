.small-btn {
    cursor: pointer;
    color: #444444;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: #000000;
        border: solid 1px #ff8e8e;
        border-radius: 4px;
        background: #ff000004;
    }

    &.disabled {
        color: #9e9e9e;
        border: 0;
        background: none;
        cursor: not-allowed;
    }
}