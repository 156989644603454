.payment-info-modal {
    .payment-info-modal-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .payment-info {
            display: flex;
            align-items: center;

            .text-part {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                align-items: stretch;

                .payment-row {
                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;

                    .label {
                        flex: 0 0 auto;
                        width: 160px;
                        font-size: 12px;
                    }

                    .value {
                        flex: 1 1 auto;
                        font-weight: bold;
                    }
                }
            }

            .qr-part {
                img {
                    width: 160px;
                    height: 160px;
                }
            }
        }

        .note {
            margin: 20px 0 0 0;
        }

        .buttons-row {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            flex-flow: row-reverse;
            padding-top: 10px;
        }
    }

    @media only screen and (max-width: 550px) {
        .payment-info-modal-content {
            .payment-info {
                flex-direction: column;
                align-items: stretch;

                .text-part {
                    .payment-row {
                        flex-wrap: wrap;

                        .value {
                            width: 180px;
                            margin-bottom: 5px;
                        }
                    }
                }

                .qr-part {
                    text-align: center;
                }
            }
        }
    }
}
