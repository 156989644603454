.table-menu {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    
    & > .table-menu-item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        border: 1px solid transparent;
        border-radius: 10px;
        padding: 10px;
        margin: 20px;
        color: black;
        text-decoration: none;

        & > img {
            width: calc(50vw - 25px);
            height: calc(50vw - 25px);
            max-width: 200px;
            max-height: 200px;
        }

        &:hover {
            color: black;
            text-decoration: none;
            border-color: #ff3333;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #ff3333;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px #ff3333;
            background: #ff000004;
        }
    }
}