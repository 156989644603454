.billing-process-info {
    background-color: #e6f7ff;

    & > .title {
        font-weight: bold;
        padding: 5px;
    }

    & > .info-item {
        font-size: 14px;
        display: flex;
        align-items: center;

        & > .label {
            padding: 2px 5px;
            min-width: 250px;
        }
    }

    .job-info {
        display: flex;
        align-items: center;

        & > * {
            padding: 2px 5px;
        }
    }
}

.page-content {
    & > *:not(:first-child) {
        margin-top: 10px;
    }
}

.imsp-admin-section {
    height: 100%;
    overflow: auto;

    .page-container {
        height: 100%;
        overflow: auto;

        .page-content {
            height: 100%;
            overflow: auto;

            .billing-list {
                height: 100%;
                overflow: auto;
            }
        }
    }
}
