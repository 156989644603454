.admin-payment-container {
    .col-date {
        width: 110px;
    }
    .col-type {
        width: 100px;
    }
    .col-amount {
        width: 100px;
    }

    .imsp-table {
        tbody tr {
            &.selected {
                font-weight: bold;
            }

            &.deposit {
                &:nth-child(odd) {
                    background: #eeffee;

                    &.selected {
                        background: #9fdf9f;
                    }
                }
                &:nth-child(even) {
                    background: #d9f2d9;

                    &.selected {
                        background: #9fdf9f;
                    }
                }
            }

            &.payment {
                &:nth-child(odd) {
                    background: #ffeeee;

                    &.selected {
                        background: #ff8080;
                    }
                }
                &:nth-child(even) {
                    background: #ffdddd;

                    &.selected {
                        background: #ff8080;
                    }
                }
            }

            &.storno {
                &:nth-child(odd) {
                    background: #e6f5ff;

                    &.selected {
                        background: #66c2ff;
                    }
                }
                &:nth-child(even) {
                    background: #b3e0ff;

                    &.selected {
                        background: #66c2ff;
                    }
                }
            }

            &.refund {
                &:nth-child(odd) {
                    background: #ffffe6;

                    &.selected {
                        background: #ffe066;
                    }
                }
                &:nth-child(even) {
                    background: #ffffb3;

                    &.selected {
                        background: #ffe066;
                    }
                }
            }
        }
    }
}