.course-notification-item {
    font-size: 14px;
    display: flex;
    align-items: center;

    .form-control.course-notification-interval {
        width: 70px;
    }

    .course-notification-type {
        flex: 0 0 250px;
    }

    & > *:not(:first-child) {
        margin-left: 10px;
    }

    .course-notification-rem {
        margin-left: auto;
    }
}