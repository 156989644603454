.lesson-time {
    display: flex;
    align-items: center;
    white-space: nowrap;

    & > *:not(:first-child) {
        margin-left: 5px;
    }

    .lt-day {
        width: 20px;
    }

    .lt-date {
        width: 45px;
        // text-align: right;
    }

    .lt-time {
        width: 45px;
        // text-align: right;
    }
}