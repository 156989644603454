.container-fluid {
    padding: 0;
}

.link {
    color: #fff;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: #a01926;
        cursor: pointer;
        text-decoration: none;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #007bff;
    text-decoration: none;
    font-size: 12px;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}

.btn-imsp {
    color: #fff;
    background-color: #cc2031;
    border-color: #cc2031;

    &:hover {
        color: #fff;
        background-color: #b11c2a;
        border-color: #cc2031;
    }

    &.disabled:hover {
        color: #fff;
        background-color: #cc2031;
        border-color: #cc2031;
    }
}

.btn-back {
    width: 34px;
    height: 34px;
    border-radius: 16px;
    margin: 2px 5px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 799px) {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        font-size: 14px;
    }
}

.import-btn {
    position: relative;
    overflow: hidden;

    & > input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
    }
}

.app-page-container {
    flex: 1 1;
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;

    &.print {
        justify-content: flex-start;
        overflow: unset;
    }
}

.imsp-view-section,
.imsp-lector-section {
    flex: 1 1 auto;
    display: flex;
}

.page-container {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
    max-width: 1920px;

    .page-menu {
        flex: 0 0 260px;
        // width: 160px;
        display: flex;
        flex-direction: column;
        // align-items: stretch;

        & > *:not(:first-child) {
            margin-top: 10px;
        }

        .filter {
            & > *:not(:first-child) {
                margin-top: 5px;
            }

            .filter-group {
                padding: 0 5px;

                label {
                    margin: 0;
                    font-size: 14px;
                }
            }

            .check-filter {
                padding: 0 5px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .label {
                    flex: 1 1 auto;
                    margin: 0;
                    padding: 5px 0;
                }

                input {
                    flex: 0 0 auto;
                    width: 24px;
                }

                &:hover {
                    .label {
                        // font-weight: bold;
                        // color: #000088;
                    }

                    // background: #00000011;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            padding: 5px;

            & > *:not(:first-child) {
                margin-top: 5px;
            }
        }

        .delimiter {
            height: 2px;
            background: #a6a6a6;
            margin: 15px 5px 0 5px;
        }
    }

    .page-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 5px;
    }

    .imsp-table {
        width: 100%;
        border-radius: 2px;
        table-layout: fixed;

        thead {
            background: #d9d9d9;

            th {
                padding: 5px;
            }
        }

        tbody {
            background: #f2f2f2;

            tr {
                overflow-wrap: break-word;

                &:nth-child(odd) {
                    background: #fbfbfb;
                }

                td {
                    position: relative;
                    border-right: solid 1px white;
                    padding: 2px 5px;

                    &:last-child {
                        border-right: none;
                    }
                }

                &.selected {
                    background: #ffe6e6;

                    &:nth-child(odd) {
                        background: #ffcccc;
                    }
                }

                &:hover > td {
                    background: #00000011;
                }

                th {
                    background: #d9d9d9;
                }
            }
        }
    }

    .common-text {
        font-size: 14px;
    }

    &.simple-page {
        .page-menu {
            width: 220px;
        }
    }

    @media only screen and (max-width: 1023px) {
        flex-direction: column;

        .page-menu {
            & > *:not(:first-child) {
                margin-top: 0;
            }

            .filter {
                & > *:not(:first-child) {
                    margin-top: 0;
                }

                .filter-group {
                    padding: 2px 2px 0 2px;

                    label {
                        display: none;
                    }
                }
            }

            .delimiter {
                display: none;
            }
        }
    }
}

.ta-r {
    text-align: right;
}

.ta-c {
    text-align: center;
}

.settings-page {
    display: flex;
    justify-content: center;

    .settings-page-content {
        flex: 0 1 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .section {
            display: flex;
            flex-direction: column;
            padding: 20px;

            .section-title {
                font-size: 24px;
                border-bottom: solid 2px;
            }

            .section-content {
                padding: 10px 5px 5px 5px;

                .item-group {
                    display: flex;
                    align-items: center;

                    .label {
                        flex: 0 0 auto;
                        width: 140px;
                        padding: 7px;
                    }

                    .value {
                        flex: 1 1 auto;
                        min-width: 200px;
                        max-width: 400px;
                        padding: 5px;
                        word-wrap: break-word;
                    }
                }

                .buttons {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 10px;
                }
            }
        }

        @media only screen and (max-width: 779px) {
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .section {
                flex: 1 1 auto;
            }
        }

        @media only screen and (max-width: 449px) {
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;

            .section {
                flex: 1 1 auto;
                padding: 5px;

                .section-content {
                    .item-group {
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;

                        .label {
                            flex: 1 1 auto;
                            width: auto;
                            padding: 5px;
                        }

                        .value {
                            flex: 1 1 auto;
                            width: auto;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}

.buttons {
    display: flex;
    flex-direction: row-reverse;

    & > button:not(:first-child) {
        margin-right: 5px;
    }
}

.grey-link {
    color: lightgrey;

    &:hover {
        color: grey;
        text-decoration: underline;
    }
}

.red-link {
    color: #cc2031;
    font-weight: bold;

    &:hover {
        color: #a01926;
        text-decoration: underline;
    }
}

.pd-l-5 {
    padding-left: 5px !important;
}

.pd-r-5 {
    padding-right: 5px !important;
}

.pd-l-10 {
    padding-left: 10px !important;
}

.pd-r-10 {
    padding-right: 10px !important;
}

.f-70 {
    flex: 0 1 70px;
}
.f-80 {
    flex: 0 1 80px;
}
.f-90 {
    flex: 0 1 90px;
}
.f-100 {
    flex: 0 1 100px;
}
.f-120 {
    flex: 0 1 120px;
}
.f-140 {
    flex: 0 1 140px;
}
.w-90pr {
    width: 90%;
}
.w-80pr {
    width: 80%;
}
.w-70pr {
    width: 70%;
}
.w-60pr {
    width: 60%;
}
.w-50pr {
    width: 50%;
}
.w-45pr {
    width: 45%;
}
.w-40pr {
    width: 40%;
}
.w-35pr {
    width: 35%;
}
.w-30pr {
    width: 30%;
}
.w-20pr {
    width: 20%;
}
.w-15pr {
    width: 15%;
}
.w-20px {
    width: 20px;
}
.w-160px {
    width: 160px;
}
.w-200px {
    width: 200px;
}

i.fa-question.colored {
    color: #2f9ad8;
}

i.fa-check.colored {
    color: #069106;
}

i.fa-times.colored {
    color: #e22020;
}

.structure-list > .body > .item {
    .pc-style .value.course-name-cell-value {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .course-name-cell-value-name {
            font-weight: bold;
        }

        .course-name-cell-value-groups {
            font-size: 12px;
        }
    }
    .mob-style {
        .course-name-cell-value-name {
            font-weight: bold;
        }

        .course-name-cell-value-groups {
            font-size: 10px;
        }
    }
}

.b {
    font-weight: bold !important;
}

.print-header {
    font-size: 24px;
    font-weight: bold;
}

.col-yesno {
    max-width: 100px;
    width: 100px;
}