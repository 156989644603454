.photo-input {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > img {
		width: 250px;
		height: 250px;
		object-fit: contain;
	}

	.img-buttons {
		margin-top: 10px;
	}
}

.react-datepicker-wrapper {
	display: block;
}

.multiselect {
	height: 86px;
	overflow: auto;

	& > .item {
		opacity: 0.7;
		cursor: pointer;
		display: flex;
		align-items: center;

		& > .content {
			flex: 1 1 auto;
		}

		&.selected {
			opacity: 1;
			background-color: #ffe6e6;

			&:hover {
				background-color: #ffcccc;
			}
		}

		&:hover {
			background-color: #00000011;
		}
	}
}

.lessons-control {
	height: 140px;
	overflow: auto;
	padding: 2px;

	& > .item {
		border: 1px solid #ced4da;
		border-radius: 3px;
		display: flex;
		align-items: center;

		.term-id {
			width: 70px;
			padding: 0 10px;
		}

		& > .value {
			flex: 1 1 auto;
		}

		& > .react-datepicker-wrapper {
			flex: 1 1 auto;

			input {
				border: 0;
				width: 100%;
				outline: none;
			}
		}
	}
}

.label-btn {
	display: flex;
	align-items: center;
	margin-bottom: 3px;

	& > label {
		flex: 1 1 auto;
		margin: 0;
	}
}

.nav-line {
	width: 100%;
	padding: 0 20px;
	display: flex;
	// background: #f2f7fd;
	border-bottom: solid 1px black;

	& > .nav-item {
		color: #6d6d6d;
		padding: 5px;
		font-size: 18px;

		&.nav-link {
			cursor: pointer;

			&:hover {
				color: #cc2031;
			}
		}
	}

	& > .delimiter {
		padding: 5px 3px;
		font-size: 18px;
		color: #6d6d6d;
	}
}

.user-page-container {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	max-width: 1024px;

	& > .buttons {
		padding: 10px 5px 5px 5px;
		display: flex;
		// flex-wrap: wrap-reverse;
		flex-flow: row-reverse;
	}

	& > .structure-list {
		padding: 10px 5px 5px 5px;
	}
}

.control-error {
	padding: 4px 2px 2px 0px;
	font-size: 12px;
	color: red;

	&.disabled {
		display: none;
	}
}

.nice-table {
	position: relative;

	thead {
		white-space: nowrap;

		th {
			padding: 5px 15px;
		}

		&:after {
			content: " ";
			height: 1.5px;
			background: black;
			left: -4px;
			right: -4px;
			position: absolute;
		}
	}

	tbody {
		tr {
			border-bottom: solid 1px #dddddd;

			td {
				padding: 7px 15px 2px 15px;
			}

			&:hover {
				font-weight: bold;
			}
		}
	}
}

.structure-list {
	flex: 1 1 auto;
	// width: 1024px;
	// max-width: 1024px;
	display: flex;
	flex-direction: column;

	& > .header {
		position: relative;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		// background: #00000005;
		// border-top-left-radius: 10px;
		// border-top-right-radius: 10px;

		&:before {
			content: " ";
			position: absolute;
			height: 2px;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: #000000;
			z-index: 1;
		}

		.column {
			padding: 0 5px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:hover {
				border-radius: 10px;
				// background-image: radial-gradient(closest-side at 20px 50%, #00000005, transparent);
				// background-image: radial-gradient(#ff0000, transparent);
			}
		}

		& > .pc-style {
			display: flex;
			flex-direction: column;

			& > *:first-child {
				padding-top: 5px;
			}

			& > *:last-child {
				padding-bottom: 5px;
			}
		}

		& > .mob-style {
			display: none;
			flex-direction: column;

			& > *:first-child {
				padding-top: 5px;
			}

			& > *:last-child {
				padding-bottom: 5px;
			}
		}

		@media only screen and (max-width: 799px) {
			& > .pc-style {
				display: none;
			}

			& > .mob-style {
				display: flex;
			}
		}
	}

	& > .body {
		& > .item {
			position: relative;
			cursor: pointer;

			&:before {
				content: " ";
				position: absolute;
				height: 1px;
				right: 2px;
				left: 2px;
				bottom: 0;
				background-color: #c3c3c3;
				z-index: 1;
			}

			&:after {
				content: " ";
				position: absolute;
				height: 2px;
				width: 0;
				left: 2px;
				bottom: 0;
				background-color: #ff3333;
				transition: all 0.25s ease-out;
				z-index: 2;
			}

			&:hover:after {
				width: calc(100% - 4px);
			}

			.value {
				padding: 0 5px;
				display: flex;
				align-items: center;

				&.ta-c {
					justify-content: center;
				}

				&.ta-r {
					justify-content: flex-end;
				}
			}

			&.selected {
				font-weight: bold;

				&:after {
					background-color: #db1717;
					width: calc(100% - 4px);
				}
			}

			& > .pc-style {
				display: flex;
				flex-direction: column;

				& > *:first-child {
					padding-top: 5px;
				}

				& > *:last-child {
					padding-bottom: 5px;
				}
			}

			& > .mob-style {
				display: none;
				flex-direction: column;

				& > *:first-child {
					padding-top: 5px;
				}

				& > *:last-child {
					padding-bottom: 5px;
				}
			}

			@media only screen and (max-width: 799px) {
				& > .pc-style {
					display: none;
				}

				& > .mob-style {
					display: flex;
				}
			}
		}
	}

	.line {
		display: flex;
	}
}

.table-structure-list {
	flex: 0 1 auto;
	flex-direction: column;

	& > thead > tr > th > .header {
		position: relative;
		font-weight: bold;
		display: flex;
		flex-direction: column;

		&:before {
			content: " ";
			position: absolute;
			height: 2px;
			right: 0;
			left: 0;
			bottom: 0;
			background-color: #000000;
			z-index: 1;
		}

		.column {
			padding: 0 5px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:hover {
				border-radius: 10px;
			}
		}

		& > .pc-style {
			display: flex;
			flex-direction: column;

			& > *:first-child {
				padding-top: 5px;
			}

			& > *:last-child {
				padding-bottom: 5px;
			}
		}

		& > .mob-style {
			display: none;
			flex-direction: column;

			& > *:first-child {
				padding-top: 5px;
			}

			& > *:last-child {
				padding-bottom: 5px;
			}
		}

		@media only screen and (max-width: 799px) {
			& > .pc-style {
				display: none;
			}

			& > .mob-style {
				display: flex;
			}
		}
	}

	& > tbody > tr > td > .item {
		position: relative;
		cursor: pointer;

		&:before {
			content: " ";
			position: absolute;
			height: 1px;
			right: 2px;
			left: 2px;
			bottom: 0;
			background-color: #c3c3c3;
			z-index: 1;
		}

		&:after {
			content: " ";
			position: absolute;
			height: 2px;
			width: 0;
			left: 2px;
			bottom: 0;
			background-color: #ff3333;
			transition: all 0.25s ease-out;
			z-index: 2;
		}

		&:hover:after {
			width: calc(100% - 4px);
		}

		.value {
			padding: 0 5px;
			display: flex;
			align-items: center;

			&.ta-c {
				justify-content: center;
			}

			&.ta-r {
				justify-content: flex-end;
			}
		}

		&.selected {
			font-weight: bold;

			&:after {
				background-color: #db1717;
				width: calc(100% - 4px);
			}
		}

		& > .pc-style {
			display: flex;
			flex-direction: column;

			& > *:first-child {
				padding-top: 5px;
			}

			& > *:last-child {
				padding-bottom: 5px;
			}
		}

		& > .mob-style {
			display: none;
			flex-direction: column;

			& > *:first-child {
				padding-top: 5px;
			}

			& > *:last-child {
				padding-bottom: 5px;
			}
		}

		@media only screen and (max-width: 799px) {
			& > .pc-style {
				display: none;
			}

			& > .mob-style {
				display: flex;
			}
		}
	}

	&.print > tbody > tr > td > .item:hover:after {
        width: 0;
    }

	.line {
		display: flex;
	}
}

.multiline {
	white-space: pre-wrap;
}

.fa-info-circle {
	color: #cc2031;
}

.imsp-tooltip {
	background: #cc2031 !important;
	color: white !important;
}

.__react_component_tooltip.type-custom.border.place-bottom:before{
    border-bottom:8px solid #cc2031 !important;
}

.__react_component_tooltip.type-custom.place-bottom:after{
    border-bottom:8px solid #cc2031 !important;
}
