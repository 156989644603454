.import-diners-table-container {
    max-height: 300px;
    overflow: auto;
    min-height: 200px;
}

.import-diners-table {
    .col-selected {
        width: 100px;
    }

    .select-cell {
        cursor: pointer;

        i {
            width: 24px;
        }

        i.fa-question {
            color: #2f9ad8;
        }

        i.fa-check {
            color: #069106;
        }

        i.fa-times {
            color: #e22020;
        }
    }
}