.appointment-control {
    .add-btn {
        width: 100%;
        padding: 0;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .appointment-item {
        display: flex;
        align-items: center;
        height: 32px;

        & > .react-datepicker-wrapper {
            flex: 1 1 auto;

            input {
                border: 0;
                width: 100%;
                outline: none;
            }
        }

        .time-text {
            flex: 1 1 100px;
        }

        .btn.rem-btn {
            padding: 0;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    & > *:not(:first-child) {
        margin-top: 5px;
    }
}
