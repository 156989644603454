.meeting-page-container {
    max-width: 1024px;

    .header-part {
        width: 100%;
        display: flex;

        .title-part {
            flex: 1 1 auto;
            padding: 10px 30px 5px;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .title {
                font-size: 32px;
                display: flex;
                align-items: center;

                .title-text {
                    flex: 1 1 auto;
                    text-align: center;
                }
            }

            .groups {
                font-size: 13px;
                text-align: center;
                padding-left: 34px;
            }
        }

        .buttons {
            padding: 10px 30px 5px;
            display: flex;
            align-items: center;

            & > *:not(:first-child) {
                margin-left: 5px;
            }
        }
    }

    .description-part {
        padding: 10px 30px;
        text-align: justify;
    }

    .info-item {
        display: flex;
        flex-direction: column;
        padding-right: 10px;
        padding-bottom: 10px;

        .label {
            min-width: 149px;
            font-size: 13px;
        }

        .value {
            font-weight: bold;
        }
    }

    .info-part {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px 30px 0;
        align-items: stretch;

        .info-group {
            display: flex;
            flex-direction: row;
            flex-flow: row;
            flex-wrap: wrap;
        }
    }

    .lector-part {
        width: 100%;
        display: flex;

        .lector-item {
            flex: 1 1 50%;
            width: 50%;
            padding: 10px 30px;
            display: flex;
            flex-wrap: wrap;

            .lector-info {
                flex: 1 1 50%;
                width: 50%;
                min-width: 200px;
                padding-right: 20px;
                display: flex;
                flex-direction: column;
            }

            .lector-img {
                flex: 1 1 50%;
                width: 50%;
                display: flex;
                justify-content: center;
                width: 50%;

                img {
                    object-fit: contain;
                    max-height: 160px;
                }
            }

            .lector-note {
                flex: 1 1 100%;
                width: 100%;

                .info-item .value {
                    font-weight: normal;
                }
            }
        }
    }

    .note-part {
        width: 100%;
        display: flex;
        padding: 0 30px;
        flex-direction: column;

        .note-item {
            border: solid 1px black;
            border-radius: 5px;
            padding: 5px 10px;
            background-color: #ff000011;
            display: flex;

            .note-mark {
                margin-left: 0px;
            }

            &:not(:first-child) {
                margin-top: 1px;
            }
        }
    }

    .img {
        flex: 1 1 auto;
        padding: 10px;

        img {
            width: 100%;
            max-height: 400px;
            object-fit: contain;
        }
    }

    .tooltip-part {
        width: 100%;
        text-align: right;
        padding: 0 30px;
        font-size: 10px;
    }

    .note-mark {
        font-size: 10px;
        vertical-align: super;
        margin: 0 10px;
    }

    .note-mark-list {
        .note-mark {
            margin: 0 2px;

            &:first-child {
                margin-left: 10px;
            }

            &:last-child {
                margin-right: 10px;
            }
        }
    }

    .lesson-list {
        display: flex;
        align-items: flex-start;

        .lesson-list-title {
            flex: 0 0 auto;
            font-weight: bold;
            width: 160px;
        }

        .lesson-list-items {
            flex: 1 1 auto;
            display: flex;
            flex-flow: wrap;

            .lesson-list-item {
                font-weight: normal;
                padding: 0 3px;
            }
        }
    }

    .appointment-part {
        padding: 10px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .appointment-item {
            display: flex;
            align-items: center;
            padding: 2px 5px;
            min-height: 42px;

            .time {
                min-width: 150px;
                text-align: center;
            }

            .item-action {
                min-width: 160px;
                text-align: center;
            }
        }
    }

    .reg-interval {
        text-align: center;
    }

    @media only screen and (max-width: 680px) {
        .appointment-part {
            justify-content: center;
        }
    }

    @media only screen and (max-width: 799px) {
        .header-part {
            flex-direction: column;

            .title-part {
                padding: 10px 10px 0;

                .title {
                    font-size: 26px;

                    .title-text {
                        text-align: left;
                        padding-left: 10px;
                    }
                }

                .groups {
                    text-align: center;
                    padding-left: 0;
                }
            }

            .buttons {
                flex-direction: column;
                padding: 10px 10px 0;
                align-items: stretch;
            }
        }

        .tooltip-part {
            text-align: center;
            padding: 5px 10px 0;
        }

        .description-part, .appointment-part {
            padding: 10px 10px 0;
        }

        .info-item {
            .label {
                min-width: 70px;
                font-size: 12px;
            }
        }

        .info-part {
            flex-direction: column;
            padding: 10px 10px 0;

            .img {
                padding-top: 10px;

                img {
                    padding: 0;
                }
            }
        }

        .note-part {
            padding: 10px 10px 0;
        }

        .lector-part {
            flex-direction: column;
            align-items: stretch;

            .lector-item {
                flex-direction: column;
                padding: 10px 10px 0;
                width: initial;

                .lector-info {
                    padding: 0;

                    .info-item {
                        display: flex;
                        flex-direction: column;

                        .label {
                            font-size: 12px;
                        }

                        .value {
                            font-weight: bold;
                        }
                    }
                }

                .lector-img {
                    padding: 5px 10px 0;
                    width: initial;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .lesson-list {
            flex-direction: column;
        }
    }
}
