.att-view-table {
    .col-first {
        width: 110px;
        vertical-align: bottom;
    }

    .col-other {
        width: 40px;
    }

    .lesson-id {
        width: 40px;
        display: inline-block;
        white-space: nowrap;
    }

    th.rotate {
        /* Something you can count on */
        height: 140px;
        white-space: nowrap;

        & > div {
            /* Magic Numbers, 45 is really 360 - 45 */
            transform: translate(18px, 51px) rotate(315deg);
            width: 30px;

            & > span {
                border-bottom: 1px solid #fff;
                padding: 5px 10px;
            }
        }
    }

    th.all-inclusive {
        color: #cc2031;
    }

    .att-value {
        text-align: center;
    }
}

.att-sum-table {
    page-break-inside: avoid;
    margin: 4px 0 4px 0;

    .col-value {
        width: 80px;
    }
}
