.student-table {
    .group {
        font-size: 14px;
        padding: 2px 25px 0 5px;

        &.nogroup {
            color: grey;
        }
    }
    .names {
        padding: 0 5px;
    }
}