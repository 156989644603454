.app-content-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
}

.app-content-container.print {
    position: relative;
}