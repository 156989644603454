.imsp-header {
    display: flex;
    background-color: #cc2031;
    font-size: 16px;
    justify-content: center;
    min-height: 58px;

    .imsp-header-content {
        flex: 1 1 auto;
        display: flex;
        align-items: stretch;
        max-width: 1920px;

        .header-title {
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            font-size: 20px;
        }

        & > .content {
            flex: 0 0 auto;
            display: flex;
            flex-flow: row-reverse;
            align-items: center;
            padding: 3px 10px;
        }
    
        .header-link {
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
            background: transparent;
            border-radius: 5px;
            padding: 10px 15px;
            text-align: center;
            border: none;
            box-shadow: none;
    
            &:hover {
                background: #00000022;
            }

            .account-ico {
                display: none;
            }
        }
    
        .signpost {
            display: flex;
            align-items: center;
            padding: 0 5px;
            cursor: pointer;

            .signpost-img {
                display: block;
                background-image: url(/img/signpost.png);
                background-color: transparent;
                background-repeat: no-repeat;
                background-size: contain;
                width: 40px;
                height: 40px;    
            }
        }

        .imsp-logo {
            flex: 0 0 auto;
            display: flex;
            align-items: stretch;
            padding: 22px 29px 13px 29px;
            cursor: pointer;

            & > .logo {
                display: block;
                background-image: url(/img/logo.png);
                background-color: transparent;
                background-repeat: no-repeat;
                background-size: contain;
                width: 190px;
                height: 58px;
            }
        }

        @media only screen and (max-width: 959px) {
            .imsp-logo {
                flex-direction: row;
                align-items: center;
                padding: 0px 5px;

                & > .logo {
                    background-image: url(/img/hand.png);
                    width: 40px;
                    height: 40px;
                }
            }
        }

        @media only screen and (max-width: 800px) {
            .header-link {
                padding: 3px 15px;

                .account-ico {
                    display: inline-block;
                    font-size: 25px;
                }
                .account-name {
                    display: none;
                }
            }
        }

        @media only screen and (max-width: 600px) {
            .header-title {
                .title-text {
                    display: none;
                }
            }
        }

        .about-modal {
            .list {
                display: flex;
                flex-direction: column;

                .info {
                    display: flex;
                    align-items: center;

                    .label {
                        width: 140px;
                    }

                    .value {
                        flex: 1 1 auto;
                    }
                }

                .delimiter {
                    height: 10px;
                }

                .buttons {
                    margin-top: 10px;
                    display: flex;
                    flex-flow: row-reverse;
                }
            }
        }
    }
}
